import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import ContentBox from "../components/common/ContentBox"
import Section from "../components/common/Section"
import SocialModal from "../components/common/SocialModal"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { CustomRichText } from "../components/styled"
import Table from "react-bootstrap/Table"
import ChevronRightIcon from "../images/svgs/chevron-right.inline.svg"

const BreadCrumb2 = styled.h5`
  display: flex;
  margin-left: -80px;
  margin-bottom: 58px;
  margin-top: 80px;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
`
const BreadCrumb2Item = styled.div`
  margin: 0 4px;
  font-family: "CeraGR-Medium";
  font-size: 20px;
  line-height: 30px;
  color: ${props => props.theme.colors.blue};
`

export default function Template({ location, data, navigate }) {
  const [popupShow, setPopupShow] = useState("")

  const onAfterOpen = () => {}

  const categoryData = data.allPrismicCategory.nodes
  const page = data.prismicGenericPage.data
  const resources = page.resources
    .filter(resource => resource?.resource?.id)
    .sort((a, b) => {
      const dateA = new Date(a.resource.document.data.post_date)
      const dateB = new Date(b.resource.document.data.post_date)

      return dateB - dateA
    })

  return (
    <Layout location={location} type="primary">
      <Seo title={page.title.text} />

      <Section className="activity-section">
        <div className="container">
          <BreadCrumb2>
            <BreadCrumb2Item>
              <ChevronRightIcon
                css={`
                  transform: scaleX(-1);
                `}
              />
            </BreadCrumb2Item>
            <BreadCrumb2Item as={Link} to={location.state?.backTo || "/"}>
              Επιστροφή
            </BreadCrumb2Item>
          </BreadCrumb2>
        </div>
        <div className="container">
          <h4
            className="mb-5"
            style={{
              fontSize: "32px",
            }}
          >
            {page.title.text}
          </h4>
          <h5 className="mb-5">{page.subtitle.text}</h5>
          <div
            className="container"
            css={`
              margin-bottom: 32px;
            `}
          >
            <CustomRichText
              dangerouslySetInnerHTML={{ __html: page.rich_text.html }}
            />
            <div
              css={`
                max-height: 410px;
                overflow: auto;
                margin: 32px 0;
                border-radius: 4px;
                box-shadow: 0 0 0 1px rgba(0, 47, 108, 0.2);
              `}
            >
              {page.table.id && (
                <Table
                  css={`
                    border-collapse: collapse;
                    border-radius: 4px;
                    border-style: hidden;

                    &&& {
                      th {
                        background: rgba(21, 123, 255, 0.1);
                      }

                      tbody {
                        border-top: none;
                      }
                    }
                  `}
                  striped
                  bordered
                  hover
                >
                  <thead>
                    <tr>
                      <th>Θέση</th>
                      <th>Όνομα</th>
                    </tr>
                  </thead>
                  <tbody>
                    {page.table.document.data.members.map(x => (
                      <tr key={x.full_name}>
                        <td>{x.position}</td>
                        <td>{x.full_name}</td>
                      </tr>
                    ))}
                    {!Boolean(page.table.document.data.members.length) && (
                      <tr>
                        <td
                          css={`
                            text-align: center;
                          `}
                          colSpan={2}
                        >
                          -
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
          {Boolean(resources.length) && (
            <div>
              {page?.resources_title?.text && (
                <h4>{page?.resources_title?.text}</h4>
              )}
              <div
                css={`
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr;
                  gap: 30px;
                  margin-top: 32px;
                  margin-bottom: 32px;
                `}
              >
                {resources.map(resource => (
                  <ContentBox
                    key={resource.resource.document.prismicId}
                    resourceData={resource.resource.document}
                    categoryData={categoryData}
                    width="300px"
                    dashboard={true}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </Section>
      <SocialModal
        isOpen={popupShow === "modal"}
        onAfterOpen={onAfterOpen}
        onRequestClose={() => setPopupShow("")}
      />
    </Layout>
  )
}

export const query = graphql`
  query GenericQuery($id: String, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicCategory(filter: { lang: { eq: "el-gr" } }) {
      nodes {
        prismicId
        uid
        data {
          title
          description
          color
          icon {
            url
          }
          overlay_img {
            gatsbyImageData
            url
          }
        }
      }
    }
    prismicGenericPage(id: { eq: $id }) {
      prismicId
      data {
        rich_text {
          html
        }
        post_date
        resources_title {
          text
        }
        attachment_group {
          attachments {
            raw
          }
        }
        table {
          id
          uid
          document {
            ... on PrismicPopUp {
              id
              data {
                title
                members {
                  full_name
                  position
                }
              }
            }
          }
        }
        resources {
          resource {
            id
            document {
              ... on PrismicResource {
                prismicId
                uid
                data {
                  category {
                    id
                    uid
                  }
                  categories {
                    item {
                      id
                    }
                  }
                  title
                  resource_type
                  resource_photo {
                    gatsbyImageData
                    url
                  }
                  post_date
                  overlay_position
                  group_of_subtags {
                    subtag {
                      id
                      uid
                    }
                  }
                }
              }
            }
          }
        }
        subtitle {
          text
        }
        title {
          text
        }
      }
    }
  }
`
